import React from 'react';
import Navbar from '../Component/Navbar';


const styles = {
    container: {
        maxWidth: '900px',
        margin: 'auto',
        padding: '20px',
        fontFamily: "'Poppins', sans-serif",
        color: '#fff',
        backgroundColor: '#0b132b',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    heading: {
        fontSize: '2.5rem',
        fontWeight: '700',
        textAlign: 'center',
        marginBottom: '20px',
        color: '#48e6a6',
    },
    subHeading: {
        fontSize: '1.8rem',
        fontWeight: '600',
        marginTop: '30px',
        marginBottom: '10px',
    },
    paragraph: {
        fontSize: '1rem',
        lineHeight: '1.8',
        marginBottom: '15px',
        color: '#dcdcdc',
    },
    list: {
        listStyle: 'none',
        padding: 0,
        margin: '15px 0',
    },
    listItem: {
        fontSize: '1rem',
        lineHeight: '1.8',
        marginBottom: '10px',
    },

};
const AboutUs = () => {


    return (

        <div >
            <Navbar />
            <div style={styles.container} className='mt-3'>
                <h1 style={styles.heading}>About Us</h1>
                <p style={styles.paragraph}>
                    Welcome to <strong>Zenith Trade</strong>, your gateway to the future of financial investments and cryptocurrency trading.
                    At Zenith Trade, we believe in empowering investors by providing innovative tools, cutting-edge insights, and a seamless trading experience.
                    Our mission is to create a transparent and secure platform where both novice and seasoned investors can thrive in the world of digital finance.
                </p>

                <h2 style={styles.subHeading}>Who We Are</h2>
                <p style={styles.paragraph}>
                    Zenith Trade is more than just a trading platform — we are a team of passionate financial experts, blockchain enthusiasts,
                    and technology innovators. We are committed to delivering a secure, reliable, and intuitive environment where individuals
                    and businesses can trade confidently and achieve financial freedom.
                </p>

                <h2 style={styles.subHeading}>What We Do</h2>
                <ul style={styles.list}>
                    <li>🚀 <strong>Cryptocurrency Trading:</strong> Bitcoin, Ethereum, and emerging altcoins.</li>
                    <li>📈 <strong>Investment Plans:</strong> Customized financial strategies to meet your goals.</li>
                    <li>🔍 <strong>Market Insights and Tools:</strong> Stay ahead with live data, forecasts, and AI-driven analytics.</li>
                    <li>🔐 <strong>Secure Wallets:</strong> Store and manage your assets safely with top-tier encryption.</li>
                </ul>

                <h2 style={styles.subHeading}>Why Choose Us?</h2>
                <ul style={styles.list}>
                    <li>✅ <strong>Transparency and Trust:</strong> We prioritize honesty and clarity in all our dealings.</li>
                    <li>⚙️ <strong>Innovative Technology:</strong> Leverage the power of blockchain, AI, and analytics for smart trading.</li>
                    <li>📞 <strong>24/7 Customer Support:</strong> Our team is always here to guide you.</li>
                    <li>🔒 <strong>Security at the Core:</strong> Your assets and data are protected with industry-leading security protocols.</li>
                </ul>

                <h2 style={styles.subHeading}>Our Vision</h2>
                <p style={styles.paragraph}>
                    At Zenith Trade, we envision a future where financial opportunities are accessible to everyone, regardless of background or experience.
                    We aim to foster a community of informed investors and traders, building a future where people are empowered to make smart financial decisions
                    through technology and innovation.
                </p>

                <h2 style={styles.subHeading}>Join Us Today!</h2>
                <p style={styles.paragraph}>
                    Whether you're an experienced trader or just getting started, <strong>Zenith Trade</strong> is here to help you navigate the dynamic world of digital finance.
                    Together, let's unlock new possibilities and achieve financial success!
                </p>
            </div>
        </div>
    );
};



export default AboutUs;

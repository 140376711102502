import React from 'react'

const Market = () => {
    return (
        <section className="coin-list sp">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 m-auto text-center">
                        <div className="heading4">
                            <span className="span" data-aos="zoom-in-left" data-aos-duration={700}>BItCoin Market update</span>
                            <h2 className="text-anime-style-3 text-white">
                                Cryptocurrency Market Update
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="space20" />
                <div className="row">
                    <div className="col-md-12">
                        <div className="coin-list__main">
                            <div className="flat-tabs" data-aos="fade-up" data-aos-duration={800}>
                                <div className="content-tab">
                                    <div className="content-inner">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" />
                                                    <th scope="col" />
                                                    <th scope="col">Name/Pair</th>
                                                    <th scope="col">Last Price</th>
                                                    <th scope="col">24 Change</th>
                                                    <th scope="col">Chart</th>
                                                    <th scope="col">Trade</th>
                                                    <th scope="col" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="spacer">
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <span className="icon-star"><i className="fa-regular fa-star" /></span>
                                                    </th>
                                                    <td>
                                                        <a href="#">
                                                            <img src="assets/img/logo/marketing-logo1.png" alt="" />
                                                            <span>BitCoin (BSTD)</span>
                                                        </a>
                                                    </td>
                                                    <td className="boild">$56,623.54</td>
                                                    <td className="up">+1.45%</td>
                                                    <td><div id="total-revenue-chart-1" /></td>
                                                    <td><a href="#" className="btn">Trade</a></td>
                                                    <td />
                                                    <td />
                                                </tr>
                                                <tr className="spacer">
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <span className="icon-star"><i className="fa-regular fa-star" /></span>
                                                    </th>
                                                    <td>
                                                        <a href="#">
                                                            <img src="assets/img/logo/marketing-logo2.png" alt="" />
                                                            <span>Ethereum (ETH)</span>
                                                        </a>
                                                    </td>
                                                    <td className="boild">$56,623.54</td>
                                                    <td className="up">-5.12%</td>
                                                    <td><div id="total-revenue-chart-2" /></td>
                                                    <td><a href="#" className="btn">Trade</a></td>
                                                    <td />
                                                    <td />
                                                </tr>
                                                <tr className="spacer">
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <span className="icon-star"><i className="fa-regular fa-star" /></span>
                                                    </th>
                                                    <td>
                                                        <a href="#">
                                                            <img src="assets/img/logo/marketing-logo3.png" alt="" />
                                                            <span>Binance (BNB)</span>
                                                        </a>
                                                    </td>
                                                    <td className="boild">$56,623.54</td>
                                                    <td className="up">-3.75%</td>
                                                    <td><div id="total-revenue-chart-3" /></td>
                                                    <td><a href="#" className="btn">Trade</a></td>
                                                    <td />
                                                    <td />
                                                </tr>
                                                <tr className="spacer">
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <span className="icon-star"><i className="fa-regular fa-star" /></span>
                                                    </th>
                                                    <td>
                                                        <a href="#">
                                                            <img src="assets/img/logo/marketing-logo4.png" alt="" />
                                                            <span>Tether (USDT)</span>
                                                        </a>
                                                    </td>
                                                    <td className="boild">$56,623.54</td>
                                                    <td className="up">+1.45%</td>
                                                    <td><div id="total-revenue-chart-4" /></td>
                                                    <td><a href="#" className="btn">Trade</a></td>
                                                    <td />
                                                    <td />
                                                </tr>
                                                <tr className="spacer">
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <span className="icon-star"><i className="fa-regular fa-star" /></span>
                                                    </th>
                                                    <td>
                                                        <a href="#">
                                                            <img src="assets/img/logo/marketing-logo5.png" alt="" />
                                                            <span>Solana (SOL)</span>
                                                        </a>
                                                    </td>
                                                    <td className="boild">$56,623.54</td>
                                                    <td className="down">+1.45%</td>
                                                    <td><div id="total-revenue-chart-5" /></td>
                                                    <td><a href="#" className="btn">Trade</a></td>
                                                    <td />
                                                    <td />
                                                </tr>
                                                <tr className="spacer">
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <span className="icon-star"><i className="fa-regular fa-star" /></span>
                                                    </th>
                                                    <td>
                                                        <a href="#">
                                                            <img src="assets/img/logo/marketing-logo6.png" alt="" />
                                                            <span>USD Coin (USDC)</span>
                                                        </a>
                                                    </td>
                                                    <td className="boild">$56,623.54</td>
                                                    <td className="down">-2.22%</td>
                                                    <td><div id="total-revenue-chart-6" /></td>
                                                    <td><a href="#" className="btn">Trade</a></td>
                                                    <td />
                                                    <td />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img src="assets/img/shapes/chart4-shape1.png" alt="" className="sec-shape1" />
            <img src="assets/img/shapes/chart4-shape2.png" alt="" className="sec-shape2" />
        </section>

    )
}

export default Market
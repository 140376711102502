import Navbar from "./Component/Navbar";
import Hero from "./Component/Hero";
import About from "./Component/About";
import Work from "./Component/Work";
import WorkArea from "./Component/Work_Area";
import Market from './Component/Market'
import Testemonial from "./Component/Testemonial";
import Footer from "./Footer";

function App() {
  return (
    <div className="body body2" style={{ backgroundColor: 'black', color: 'white' }}>


      <Navbar />
      <Hero />
      <About />
      <Work />
      <WorkArea />
      <Market />
      <Testemonial />
      <Footer />

    </div>
  );
}

export default App;

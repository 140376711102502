import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './App';
import AboutUs from './AboutUS/Aboutus';
import Login from './Form/Login';
import SignUp from './Form/SignUp';
import Contact from './Contact/ContactUs';


const Routess = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/aboutus" element={<AboutUs />} />

                <Route path="/user" element={<Login />} />
                <Route path="/user/signup" element={<SignUp />} />
                <Route path="/Contact" element={<Contact />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Routess;

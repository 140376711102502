import React from 'react'

const Testemonial = () => {
    return (

        <div className="tes2 sp2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 m-auto text-center">
                        <div className="heading2">
                            <span className="span" data-aos="zoom-in-left" data-aos-duration={700}><span>TESTIMONIALS</span></span>
                            <h2 className="text-anime-style-3">From Our Investor</h2>
                        </div>
                    </div>
                </div>
                <div className="space30" />
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="space40" />
                        <div className="main-image image-anime" data-aos="flip-right" data-aos-duration={800}>
                            <img src="assets/img/testimonial/tes2-img.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 _relative">
                        <div className="tes2-slider" data-aos="fade-up" data-aos-duration={800}>
                            <div className="single-slider">
                                <div className="icon">
                                    <img src="assets/img/icons/tes2-qute.png" alt="" />
                                </div>
                                <div className="space20" />
                                <p className="pera">
                                    "Joining this ICO was one of the best decisions I've made in
                                    the crypto space. The team's dedication a to innovation and
                                    their commitment to delivering &amp; on promises sets them apart.
                                    I'm excited to be.”
                                </p>
                                <div className="bottom-area">
                                    <div className="image">
                                        <img src="assets/img/testimonial/tes2-bottom-img.png" alt="" />
                                    </div>
                                    <div className="heading">
                                        <h5><a href="#">Shakib Mahmud</a></h5>
                                        <p>Operating Officer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="single-slider">
                                <div className="icon">
                                    <img src="assets/img/icons/tes2-qute.png" alt="" />
                                </div>
                                <div className="space20" />
                                <p className="pera">
                                    "Joining this ICO was one of the best decisions I've made in
                                    the crypto space. The team's dedication a to innovation and
                                    their commitment to delivering &amp; on promises sets them apart.
                                    I'm excited to be.”
                                </p>
                                <div className="bottom-area">
                                    <div className="image">
                                        <img src="assets/img/testimonial/tes2-bottom-img.png" alt="" />
                                    </div>
                                    <div className="heading">
                                        <h5><a href="#">Shakib Mahmud</a></h5>
                                        <p>Operating Officer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nav-area">
                            <button className="arrow-left">
                                <i className="fa-solid fa-angle-left" />
                            </button>
                            <button className="arrow-right">
                                <i className="fa-solid fa-angle-right" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <img src="assets/img/shapes/tes2-shape.png" alt="" className="sec-shape animate3" />
        </div>

    )
}

export default Testemonial
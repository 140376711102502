import React from 'react'

const Footer = () => {
    return (
        <div className="footer2 _relative">
            <div className="container">
                <div className="footer-bg">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="footer-logo-area">
                                <a href="index-2.html" className="logo">
                                    <img src="assets/img/logo/footer-logo2.png" alt='' />
                                </a>
                                <a href="signup.html" className="btn_theme2 btn_theme_active2 mt_40 wow fadeInDown" data-wow-duration="0.8s">Join Now <i className="fa-solid fa-arrow-right" /><span /></a>
                            </div>
                        </div>
                        <div className="col-lg col-md-4 col-6">
                            <div className="single-footer-items">
                                <h3>Explore</h3>
                                <ul className="menu-list">
                                    <li><a href="#">Tokens Sale</a></li>
                                    <li><a href="#">Ecosystem</a></li>
                                    <li><a href="#">Wallets Sale&nbsp;</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg col-md-4 col-6">
                            <div className="single-footer-items">
                                <h3>Solution</h3>
                                <ul className="menu-list">
                                    <li><a href="#">Token Suite</a></li>
                                    <li><a href="#">Work Process</a></li>
                                    <li><a href="#">Tokenisation</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg col-md-4 col-6">
                            <div className="single-footer-items pl-5">
                                <h3>Quick Links</h3>
                                <ul className="menu-list">
                                    <li><a href="#">Our Features </a></li>
                                    <li><a href="#">Why Blockchain</a></li>
                                    <li><a href="#">Our Blog</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-6">
                            <div className="single-footer-items">
                                <h3>Need Help?</h3>
                                <ul className="contact-info">
                                    <li><a href="tel:+(1)1234567890">+(1) 123 456 7890</a></li>
                                    <li>
                                        <a href="mailto:Zenith Tradedemo@.com">info@zenithtrade.com</a>
                                    </li>
                                    <li><a href="#">zenithtrade.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area _relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="coppyright">
                                <p>Copyright © 2024 Zenith Trade. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img src="assets/img/shapes/footer2-element.png" alt="" className="shape" />
        </div>

    )
}

export default Footer
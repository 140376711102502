import React from 'react';


const About = () => {
    return (
        <div className="about1 sp">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about1-images">
                            <div className="image1">
                                <img
                                    src="assets/img/19197351.jpg"
                                    alt="Background visual for Zenith Trade"
                                />
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="heading1">
                            <span className="span">ABOUT ZENITH TRADE</span>
                            <p style={{ color: 'white' }}>
                                At Zenith Trade, we believe crypto staking is the future and a
                                vehicle for change. Built with Zenith Trade is our initiative to
                                provide opportunities, improve lives, and make the world a
                                better place for communities that need it the most, using crypto.
                                <br /><br />
                                Zenith Trade will soon launch its own token. You can “stake”
                                some of your tokens (holdings) and earn a percentage-rate reward
                                over time. Built with Zenith Trade will soon introduce NFT and
                                Metaverse technologies, which will merge innovative ideas to
                                create a prosperous future for mankind. Join us! Let's change
                                the world together.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;

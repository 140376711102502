import React from 'react'

const Hero = () => {
    return (
        <div className="hero-area2" style={{ backgroundImage: 'url(assets/img/bg/hero2-bg.png)' }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="main-heading">
                            <span className="span" data-aos="fade-right" data-aos-duration={700}>Invest In The Future Of Zenith Trade</span>
                            <h1 data-aos="fade-right" data-aos-duration={900}>
                                The Future is Here Invest in
                                <span className="after">Zenith Trade</span>
                            </h1>
                            <div className="space16" />
                            <p data-aos="fade-right" data-aos-duration={1100}>
                                Zenith Trade, where innovation meets investment. Our
                                groundbreaking an platform is poised to revolutionise the Zenith
                                Trade landscape through.
                            </p>
                            <div className="space30" />
                            <div className="buttons" data-aos="fade-right" data-aos-duration={1300}>
                                <a href="signup/.html" className="btn_theme btn_theme_active mt_40 wow fadeInDown" data-wow-duration="0.8s">Get Started <i className="fa-solid fa-arrow-right" /><span /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="images-area">
                            <div className="image1 reveal">
                                <img src="assets/img/hero/hero2-main-img.png" alt="" />
                            </div>
                            <div className="image2 animate6">
                                <img src="assets/img/hero/hero2-coin.png" alt="" />
                            </div>
                            <div className="image3 animate1 reveal">
                                <img src="assets/img/hero/hero2-dashboard1.png" alt="" />
                            </div>
                            <div className="image4 animate3 reveal">
                                <img src="assets/img/hero/hero2-dashboard2.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img src="assets/img/shapes/hero2-circle-shape1.png" alt="" className="circle-shape1" />
            <img src="assets/img/shapes/hero2-circle-shape2.png" alt="" className="circle-shape2 shape-animaiton4" />
            <img src="assets/img/shapes/hero2-bet-coin-shape.png" alt="" className="shape1 animate1" />
            <img src="assets/img/shapes/hero2-bottom-shape.png" alt="" className="shape2 animate6" />
        </div>


    )
}

export default Hero
// ContactUs.js
import React, { useState } from 'react';
import './Contact.css';
import Navbar from '../Component/Navbar';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        userId: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert('Your message has been sent successfully!');
        setFormData({ name: '', email: '', userId: '', message: '' });
    };

    return (
        <div>
            <Navbar active="static" />
            <div className="contact-wrapper" >
                <div className="contact-container" style={{ marginTop: '10px' }} >
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <h2 className="form-title">Contact Us</h2>

                        <div className="form-group">
                            <input
                                type="text"
                                name="name"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="email"
                                name="email"
                                placeholder="Your Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                name="userId"
                                placeholder="Your User ID"
                                value={formData.userId}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <textarea
                                name="message"
                                rows="4"
                                placeholder="Your Message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            ></textarea>
                        </div>

                        <button type="submit" className="submit-button">
                            Send Message
                        </button>
                    </form>
                </div>
            </div>
        </div >
    );
};

export default ContactUs;

import React from 'react'

const Work = () => {
    return (
        <div className="about2 sp">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 m-auto text-center">
                        <div className="heading2">
                            <span className="span" data-aos="zoom-in-left" data-aos-duration={700}><span>what is Zenith Trade?</span></span>
                            <h2 className="text-anime-style-3">What We Do Zenith Trade</h2>
                        </div>
                    </div>
                </div>
                <div className="space60" />
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="space30" />
                        <div className="about2-images">
                            <div className="image1">
                                <img src="assets/img/bg/about2-bg-shape.png" alt="" />
                            </div>
                            <div className="image2">
                                <img src="assets/img/about/about2-main-img.png" alt="" />
                            </div>
                            <div className="image3 animate6">
                                <img src="assets/img/hero/hero2-coin.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about2-boxs">
                            <div className="single-box" data-aos="fade-up" data-aos-duration={700}>
                                <div className>
                                    <div className="icon">
                                        <img src="assets/img/icons/about2-icon1.svg" alt="" />
                                    </div>
                                </div>
                                <div className="heading2">
                                    <h4><a href="#">Automatic Conversion</a></h4>
                                    <div className="space16" />
                                    <p>
                                        Our team of experts has meticulously crafted Zenith Trade
                                        address the key a challenges facing
                                    </p>
                                </div>
                            </div>
                            <div className="single-box" data-aos="fade-up" data-aos-duration={900}>
                                <div className>
                                    <div className="icon">
                                        <img src="assets/img/icons/about2-icon2.svg" alt="" />
                                    </div>
                                </div>
                                <div className="heading2">
                                    <h4><a href="#">Simple to manage</a></h4>
                                    <div className="space16" />
                                    <p>
                                        With Zenith Trade, investors have the chance to a
                                        participate in a project that not only offers.
                                    </p>
                                </div>
                            </div>
                            <div className="single-box" data-aos="fade-up" data-aos-duration={1100}>
                                <div className>
                                    <div className="icon">
                                        <img src="assets/img/icons/about2-icon3.svg" alt="" />
                                    </div>
                                </div>
                                <div className="heading2">
                                    <h4><a href="#">quick to set up</a></h4>
                                    <div className="space16" />
                                    <p>
                                        Our experienced team is dedicated to Zenith Trade building
                                        platform that streamlines processes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img src="assets/img/shapes/about2-shape.png" alt="" className="sec-shape" />
        </div>


    )
}

export default Work
import React from 'react'
import { Link } from 'react-router-dom'

const Login = () => {
    return (
        <form method="post" action="" onsubmit="javascript:return WebForm_OnSubmit();" id="ctl00">
            <div className="aspNetHidden">
                <input type="hidden" name="__EVENTTARGET" id="__EVENTTARGET" defaultValue />
                <input type="hidden" name="__EVENTARGUMENT" id="__EVENTARGUMENT" defaultValue />
                <input type="hidden" name="__VIEWSTATE" id="__VIEWSTATE" defaultValue="070pPI6gIUgQUoRmHvQLHsB5DjICOF2qGS6k+MxOa/qE6io0ktGBPuvwLQ+0hoUa1ZgYpqEtlzKTJ7CgLMr6YW6Osss=" />
            </div>
            <div className="aspNetHidden">
                <input type="hidden" name="__VIEWSTATEGENERATOR" id="__VIEWSTATEGENERATOR" defaultValue="C2EE9ABB" />
                <input type="hidden" name="__EVENTVALIDATION" id="__EVENTVALIDATION" defaultValue="LmZnNl82O0vTQPUfNm8hgay0Ueg90ve+sMnc4tty/QtneUSQBi0Zr26G7fbhUFfrQEHd1BIITjEmqq9YGDO+iXqSnLn1RuV3xC78lTA0w2DwWFjDMMALHISsbhvcDOVNQXVDNU7WKC2myHzxTGNJgKP8O2U=" />
            </div>
            {/* Preloader Start */}
            {/* <div className="preloader preloader2">
                <div className="loading-container">
                    <div className="loading loading2" />
                    <div id="loading-icon"><img src="assets/img/logo/preloader-logo2.png" alt /></div>
                </div>
            </div> */}
            {/* Preloader End */}
            {/*=====progress END=======*/}
            {/*=====Mobile header end=======*/}
            {/*=====LOGIN AREA START=======*/}
            <div className="login-page sp">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 m-auto">
                            <div className="login-bg">
                                <div className="login-area">
                                    <center><img src="assets/img/logo/header-logo2.png" alt='' /></center>
                                    <h5 align="center">Login Your Account</h5>
                                    <div className="form-area">
                                        <table id="Login1" className="register-form-box" cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
                                            <tbody><tr>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="single-input">
                                                                <input name="Login1$UserName" type="text" id="Login1_UserName" className="form-control" placeholder="User Id" /><span id="Login1_UserNameRequired" title="User Name is required." style={{ visibility: 'hidden' }}>*</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="single-input">
                                                                <input name="Login1$Password" type="password" id="Login1_Password" className="form-control" placeholder="Password" />
                                                                <span id="Login1_PasswordRequired" title="Password is required." style={{ visibility: 'hidden' }}>*</span>
                                                            </div>
                                                        </div>
                                                        <p />
                                                        <div className="button">
                                                            <input type="submit" name="Login1$LoginButton" defaultValue="Login" onclick="javascript:WebForm_DoPostBackWithOptions(new WebForm_PostBackOptions(&quot;Login1$LoginButton&quot;, &quot;&quot;, true, &quot;Login1&quot;, &quot;&quot;, false, false))" id="Login1_LoginButton" className="theme-btn1 btn btn--ripple ripple" style={{ width: '100%' }} />
                                                        </div>
                                                        <div className="qr-code-area">
                                                            <p>Not A Member? <Link to="/user/signup">Register</Link></p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody></table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*===== FOOTER AREA END =======*/}
        </form>

    )
}

export default Login
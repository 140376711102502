import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Ensure this import is present

const Navbar = ({ active }) => {

    const Navigate = useNavigate()
    const Login = () => {
        Navigate("/Login")
    }

    const SignUp = () => {
        Navigate("/SignUp")
    }
    return (
        <nav className={active ? "navbar navbar-expand-lg text-dark " + active : "navbar navbar-expand-lg text-dark sticky-top"} style={{ backgroundColor: 'rgb(13,13,13)' }}>
            <div className="container-fluid">
                <img
                    src="assets/img/logo/header-logo2.png"
                    alt="Logo"
                    className="img-fluid"
                    style={{ height: '50px' }}
                />
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span><i className="fas fa-bars text-white"></i></span>
                </button>
                <div className="collapse navbar-collapse ms-5" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link active text-white" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white" to="/AboutUs">About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white" to="#">Plan</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white" to="/Contact">Contact Us</Link>
                        </li>
                    </ul>
                    <form className="d-flex" role="search">
                        <button className="btn btn-outline-success m-3" onClick={Login} type="button">Login</button>
                        <button className="btn btn-outline-success m-3" onClick={SignUp} type="button">Sign Up</button>
                    </form>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;

import React from 'react'

const Work_Area = () => {
    return (
        <div className="work2 pb120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 m-auto text-center">
                        <div className="heading2">
                            <span className="span" data-aos="zoom-in-left" data-aos-duration={700}><span>Work process</span></span>
                            <h2 className="text-anime-style-3">How It Works</h2>
                        </div>
                    </div>
                </div>
                <div className="space30" />
                <div className="row">
                    <div className="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-duration={1100}>
                        <div className="work2-box-area">
                            <div className="icon-area">
                                <div className="icon">
                                    <img src="assets/img/icons/work2-icon3.svg" alt='' />
                                </div>
                            </div>
                            <div className="single-box">
                                <div className="heading2">
                                    <h4><a href="login.html">Download Wallet</a></h4>
                                    <div className="space16" />
                                    <p>
                                        Bitcoin is received, stored, and sent using software known
                                        as a Bitcoin Wallet. Download the official Bitcoin Wallet
                                        for free.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-duration={900}>
                        <div className="work2-box-area active">
                            <div className="icon-area">
                                <div className="icon">
                                    <img src="assets/img/icons/work2-icon2.svg" alt='' />
                                </div>
                            </div>
                            <div className="single-box">
                                <div className="heading2">
                                    <h4><a href="login.html">Safe And Secure</a></h4>
                                    <div className="space16" />
                                    <p>
                                        Our team ultimate solution for Zenith Trade. By leveraging
                                        safe blockchain technology, we're revolutionising safe &amp;
                                        secure.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-duration={700}>
                        <div className="work2-box-area">
                            <div className="icon-area">
                                <div className="icon">
                                    <img src="assets/img/icons/work2-icon1.svg" alt='' />
                                </div>
                            </div>
                            <div className="single-box">
                                <div className="heading2">
                                    <h4><a href="login.html">Download And Sell</a></h4>
                                    <div className="space16" />
                                    <p>
                                        ICO, you're not just investing in a project – you're
                                        investing in a vision for a brighter future. Join us today
                                        and be part of Coin.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img className="shape" src="assets/img/shapes/work2-shape.png" alt='' />
        </div>

    )
}

export default Work_Area